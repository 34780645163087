import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, mapTo, throwError, } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DataService {
    purchaseUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/purchase.json`;
    productUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/product.json`;
    supplierUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/supplier.json`;
    supplierIdUrl =`https://auraconst-e5e30-default-rtdb.firebaseio.com/supplier`;
    purchaseOrderUrl =`https://auraconst-e5e30-default-rtdb.firebaseio.com/purchaseOrder.json`;
    inventoryUrl = `https://auraconst-e5e30-default-rtdb.firebaseio.com/stockAvailable.json`;
    expensesUrl = `https://https://auraconst-e5e30-default-rtdb.firebaseio.com/expenses.json`;
    purchaseIdUrl=`https://auraconst-e5e30-default-rtdb.firebaseio.com/purchase`;

    constructor(private http:HttpClient) {}

    create(data: any) {
        return this.http.post(`${this.purchaseUrl}`, data).pipe(
           mapTo(true),
          catchError(error => {
            return error;
          })
        );
      }
      createInventory(data: any) {
        return this.http.post(`${this.inventoryUrl}`, data).pipe(
           mapTo(true),
          catchError(error => {
            return error;
          })
        );
      }
      createExpenses(data: any) {
        return this.http.post(`${this.expensesUrl}`, data).pipe(
           mapTo(true),
          catchError(error => {
            return error;
          })
        );
      }
      
      get(): Observable<any> {
        return this.http.get(this.purchaseUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getSupplier(): Observable<any> {
        return this.http.get(this.supplierUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getsupplierById(id: any): Observable<any | boolean> {
        return this.http.get(`${this.supplierIdUrl}/${id}.json`).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
      updateSupplier(data:any,id: any): Observable<any | boolean> {
        return this.http.put(`${this.supplierIdUrl}/${id}.json`,data).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
      getProduct(): Observable<any> {
        return this.http.get(this.productUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }
      getPruchase(): Observable<any> {
        return this.http.get(this.purchaseOrderUrl).pipe(
          catchError(error => {
            return throwError(error);
          })
        );
      }

      update(data:any,id: any): Observable<any | boolean> {
        return this.http.put(`${this.purchaseIdUrl}/${id}.json`,data).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
      getById(id: any): Observable<any | boolean> {
        return this.http.get(`${this.purchaseIdUrl}/${id}.json`).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
      deleteById(id: any): Observable<any | boolean> {
        return this.http.delete(`${this.purchaseIdUrl}/${id}.json`).pipe(
          map((value: any) => {
            console.log(value)
            return value;
          }),
          catchError(error => {
            return error;
          })
        );
      }
      
}