import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { AuthService } from '../../../services/auth.service';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  project: any = {};
  tablerow:boolean=false;
  states: string[] = ['State 1', 'State 2', 'State 3'];
  purchaseMode:string[]=['debit','credit']
  paymentMode:string[]=['cash','checque']
  purchaseForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  productDetails:any[]=[];
  supplier:any;
  supplierName:any[]=[];
  sum_array:any[]=[]
  stockData:any[]=[];
  bill:string|undefined;
  uid:any;
  keyword = 'productName'
  product:string[]=[]
  ispurchase:boolean = false;
  ispayment:boolean = false;
  isEnabled:boolean = false;
  isDisabled = false;
  quantity :any;
  productamt:any;
  productName:any[]=[];
  rows: any[]=[];
  rows1:any[]=[];
  gst=false;
   keyword1 = 'pocode'
   pocode:any[]=[];
  btnEnable:boolean=false;
  iscredit:boolean=false;
  isdebit:boolean=false;
  iscash:boolean=false;
  ischeque:boolean=false;
  edit1:boolean=false;
  totalAmount1:any;
  id:any
  purchaseEntry:any[]=[];
 
  

  constructor(private data: DataService,
   private toastr:NotificationService,
    private formBuilder: FormBuilder,
    private router:Router,
    private route:ActivatedRoute,

    private auth:AuthService,
  ) {
    this.rows = [];
    this.addRow();
    this.calculate();
  }

  ngOnInit()  {

    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }

    this.purchaseForm= this.formBuilder.group({
      companyName: ["Aura Contrivers"],
      userId: ["",],
      invoiceNo: [],
      productName: [],
      quantity: [],
      description: [],
      pocode: ["", Validators.required],
      supplierName: [""],
      pe_date: [""],
      totalProducts: ["", Validators.required],
      orderAmount: ["", Validators.required],
      totalGst: ["", Validators.required],
      totalDiscount: ["", Validators.required],
      roundOff: ["", Validators.required],
      totalAmount: ["", Validators.required],
      purchaseMode: ["", Validators.required],
      projectName: ["", Validators.required],
      siteNo: ["", Validators.required],
      paymentMode: ["", Validators.required],
      chequeNo: [""],
      productAmount:[""],
      accountNo: ["", Validators.required],
      withdrawDate: ["", Validators.required],
      transactionNo: ["", Validators.required],
      receiptNo: ["", Validators.required],
    });

    this.auth.getUser().subscribe((user:any)=>{
      if(user){
        console.log(user.multiFactor.email)
      this.uid=user.uid
      }
    })
    this.purchaseForm.patchValue({
      pe_date:moment().format()
    })
   
  }

  addRow(){
    const row = this.formBuilder.group({
      productName: ['', Validators.required],
      quantity: [0, Validators.required],
      description: ['', Validators.required],
      productAmount:[0]
    });
    this.rows.push(row);
   //this.getData()
   
  }
  add() {
      this.addRow();
    
  }
  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
      this.purchaseEntry.push(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.purchaseForm.patchValue({
      companyName: data.companyName,
      pocode: data.pocode,
      
      userId:data.userId,
      invoiceNo: data.invoiceNo,
      productName:data.productName,
      quantity: data.quantity,
      description: data.description,
     
      supplierName: data.supplierName,
      pe_date: data.pe_date,
      totalProducts: data.totalProducts,
      orderAmount: data.orderAmount,
      totalGst: data.totalGst,
      totalDiscount: data.totalDiscount,
      roundOff: data.roundOff,
      totalAmount: data.totalAmount,
      purchaseMode: data.purchaseMode,
      projectName: data.projectName,
      siteNo: data.siteNo,
      paymentMode: data.paymentMode,
      chequeNo: data.chequeNo,
      productAmount:data.productAmount,
      accountNo: data.accountNo,
      withdrawDate: data.withdrawDate,
      transactionNo: data.transactionNo,
      receiptNo: data.receiptNo,
    })
  }

  
  getAllAmount(){
   const totalAmount1 = this.purchaseForm.get('totalAmount')?.value || 0;
   console.log(totalAmount1)
   if(totalAmount1 < 0){
    const productAmount = this.purchaseForm.get('productAmount')?.value || 0;
    const quantity = this.purchaseForm.get('quantity')?.value || 0;
    console.log(productAmount, quantity, productAmount*quantity)
    const sum = Number(productAmount) * Number(quantity)
    this.purchaseForm.patchValue({
      totalAmount:sum
    })
    
  } else {
    const productAmount = this.purchaseForm.get('productAmount')?.value || 0;
    const quantity = this.purchaseForm.get('quantity')?.value || 0;
    const sum = Number(productAmount) * Number(quantity)
    const totalSum = Number(sum) + Number(totalAmount1)
   console.log( totalSum)
   this.purchaseForm.patchValue({
    totalAmount:totalSum
   })
  }
  }

  remove(index: number) {
    if (this.rows.length > 1) {
      this.rows.splice(index, 1);
    }
  }
  onCancel(){
    this.purchaseForm.reset()
  }


  goBack() {
    this.router.navigate(['/dashboard/purchase']);
  }
  get f() {
    return this.purchaseForm.controls;
  }
  getAllData(){
    this.productDetails.push({
      productName:this.purchaseForm.get('productName')?.value,
      quantity:this.purchaseForm.get('quantity')?.value,
      description:this.purchaseForm.get('description')?.value,
      productAmount:this.purchaseForm.get('productAmount')?.value,
    })
     this.totalAmount1 = this.purchaseForm.get('totalAmount')?.value || 0;
   console.log(this.totalAmount1)
   if(this.totalAmount1 < 0){
    const productAmount = this.purchaseForm.get('productAmount')?.value || 0;
    const quantity = this.purchaseForm.get('quantity')?.value || 0;
    const gst = this.purchaseForm.get('gst')?.value | 0;
    const roundoff = this.purchaseForm.get('roundOff')?.value | 0;
    console.log(productAmount, quantity, productAmount*quantity)
    const sum = Number(productAmount) * Number(quantity) + Number(gst) + Number(roundoff)
    this.purchaseForm.patchValue({
      totalAmount:sum,
      orderAmount:sum
    })
    
  } else {
    const productAmount = this.purchaseForm.get('productAmount')?.value || 0;
    const quantity = this.purchaseForm.get('quantity')?.value || 0;
    const gst = this.purchaseForm.get('gst')?.value | 0;
    const roundoff = this.purchaseForm.get('roundOff')?.value | 0;
    const sum = Number(productAmount) * Number(quantity) + Number(gst) + Number(roundoff)
    const totalSum = Number(sum) + Number(this.totalAmount1)
   console.log( totalSum)
   this.purchaseForm.patchValue({
    totalAmount:totalSum,
    orderAmount:totalSum
   })
  }
    
  }
  getAllDatas(){
   
    this.rows1.forEach((res:any)=>{
      if(res){
        this.productDetails.push({
          productName:res.productName,
          quantity:res.quantity,
          description:res.description,
          productAmount:this.purchaseForm.get('productAmount')?.value
        })
      }
    })
 
    // const gst = this.purchaseForm.get('gst')?.value | 0;
    // const roundoff = this.purchaseForm.get('roundOff')?.value | 0;
    //console.log(quantity)
    const totalAmount1 = this.purchaseForm.get('totalAmount')?.value | 0;
   this.productDetails.forEach((val:any)=>{
    if(totalAmount1<0){
     this.quantity = val.quantity
     this.productamt = val.productAmount
     const gst = this.purchaseForm.get('gst')?.value | 0;
     const roundoff = this.purchaseForm.get('roundOff')?.value | 0;
     const sum = Number(this.quantity) * Number(this.productamt) + Number(gst) + Number(roundoff)

     this.purchaseForm.patchValue({
      totalAmount:sum,
      orderAmount:sum
     })
    } else {
      this.quantity = val.quantity
      this.productamt = val.productAmount
      const gst = this.purchaseForm.get('gst')?.value | 0;
      const roundoff = this.purchaseForm.get('roundOff')?.value | 0;
      const sum = Number(this.quantity) * Number(this.productamt) + Number(gst) + Number(roundoff) + Number(this.totalAmount1)
 
      this.purchaseForm.patchValue({
       totalAmount:sum,
       orderAmount:sum
      })
    }
   })
   console.log(this.totalAmount1,"abz")
    console.log(this.productDetails,this.quantity,"qntys")
  }
  onSubmit(data: any) {
    if (this.purchaseForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.updateOn=moment().format();
    data.invoiceNo= uuidv4().substring(1,8)
    data.userId="abcd1"
    data.productDetails = this.productDetails
    //const stockData = []
    this.data.getsupplierById(this.supplier).subscribe((res:any)=>{
      console.log(res)
      res.updateOn = moment().format("DD-MM-YYYY")
      if(res.pendingAmount<=0){
      res.pendingAmount = Number(data.totalAmount)
      } else {
        const calcpending = Number(res.pendingAmount )
       res.newAmount =Number(data.totalAmount)
        const cacl = Number(calcpending) + Number(data.totalAmount)
        res.pendingAmount = Number(cacl)
      }
      console.log(res)
     // this.data.updateSupplier(res,this.supplier).subscribe()
    })
    console.log(data)
  this.data.update(data,this.id).subscribe(()=>{
      console.log('Purchase Created!',);
      this.toastr.showSuccess("Successfully!!","Purchase Added")
      //this.router.navigate(['/dashboard/purchase'])
    })
  }
  handleFileImage(files: FileList) {
    // @ts-ignore TS2531
    const fileToUpload: File = files.item(0);
    const reader = new FileReader();
    const fileValue = new Promise(resolve => {
      reader.onload = () => {
        this.bill = reader.result as any;
        // @ts-ignore TS2532
        if (this.bill.length * 2 > 2 ** 21) {
          alert("File exceeds the maximum size");
        } // Note: 2*2**20 = 2**21
        resolve(reader.result);
      };
    });
    reader.readAsDataURL(fileToUpload);
    return fileValue;
  }

  selectEvent(item:any) {
    // do something with selected item
    console.log(item)
   
    this.purchaseForm.patchValue({
      productName:item.productName,
      shortName:item.shortName,
      supplierName:item.supplierName
    })
  }
 
  onChangeSearch(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getProduct().subscribe((res:any)=>{
      this.productName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.product)
      //this.productName = [];
      
       //this.product.forEach((val:any)=>{
        return this.productName.filter(val  =>  val.toLowerCase().includes(search));
      //   if (val.productName && val.productName.toLowerCase().trim() === search.toLowerCase().trim()) {
      //     this.productName.push(val);
      //     console.log(this.productName);
      // }
      //})

    })
  }

  onFocused(e:any) {
    console.log(e)
    // do something
  }
  productAmount(){
  
      const quantity = this.purchaseForm.get('quantity')?.value || 0;
      const productAmount = this.purchaseForm.get('productAmount')?.value || 0;
      const sum = Number(quantity) * Number(productAmount);
      this.purchaseForm.patchValue({
        totalAmount:sum,
        orderAmount:sum
      })
    // this.getAllAmount()
  }
  selectEvent1(item:any) {
    // do something with selected item
    console.log(item,"getId")
    this.tablerow = true;
    this.purchaseForm.patchValue({
      pocode:item.pocode,
      supplierName:item.supplierName,
      projectName:item.projectName,
      siteNo:item.siteNo
    })
    item.productDetails.forEach((val:any)=>{
    this.rows1.push(val)
    })
    this.calculateTotalAmount()
    this.data.getSupplier().subscribe((res:any)=>{
      this.supplierName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.supplierName)
      this.supplierName.forEach((val:any)=>{
        this.supplier = val.id
        console.log(this.supplier)
      })
      return this.supplierName.filter(val => val.toLowerCase().includes(item.supplierName))

    })
   
  }

  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getPruchase().subscribe((res:any)=>{
      this.pocode = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.pocode)
      //this.productName = [];
      
       //this.product.forEach((val:any)=>{
        return this.pocode.filter(val  =>  val.pocode.toLowerCase().includes(search));
      //   if (val.productName && val.productName.toLowerCase().trim() === search.toLowerCase().trim()) {
      //     this.productName.push(val);
      //     console.log(this.productName);
      // }
      //})

    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }
  fieldEnable(){
   this.btnEnable = true
  }
  purchased(data:any){
  console.log(data)
  if(data == "debit"){
    console.log("deb")
    this.iscredit = false;
  } else if(data == "credit"){
    this.iscredit = true
    console.log("crd")
  } else {
    this.iscredit = false;
    this.isdebit = false
  }
  }
  payment(data:any){
    console.log(data)
    if(data == "cash"){
      console.log("deb")
      this.iscash = true
      this.ischeque=false;

    } else if(data == "checque"){
      this.ischeque = true
      this.iscash=false;
      console.log("crd")
    } else {
      this.iscredit = false;
      this.isdebit = false
    }
    }
  calculate(){
   const Amount=  this.purchaseForm.get('orderAmount')?.value || 0;
   const gts = this.purchaseForm.get('totalGst')?.value || 0;
   const roundOff = this.purchaseForm.get('roundOff')?.value || 0;
   const totalValue = Number(Amount) + Number(gts) + Number(roundOff)
   //console.log(character)
   console.log(totalValue)
   this.purchaseForm.patchValue({
    totalAmount:totalValue
   })

  }
  
  calculateTotalAmount() {
    let totalAmount = 0;
    this.rows1.forEach(row => {
      console.log(row)
      const quantity = row.quantity || 0;
      const productAmount = this.purchaseForm.get('productAmount') || 0;
      totalAmount += Number( quantity) * Number(productAmount);
    });

    const gst = this.purchaseForm.get('totalGst')?.value || 0;
    const roundOff = this.purchaseForm.get('roundOff')?.value || 0;
    totalAmount += gst + roundOff;

    this.purchaseForm.patchValue({
      totalAmount: totalAmount
    });
  }

  productAmountChanged() {
    this.calculateTotalAmount();
  }

  roundOffChanged() {
    const roundOff = this.purchaseForm.get('roundOff')?.value || 0;
    if (roundOff > 0) {
      this.purchaseForm.patchValue({
        roundOff: Math.abs(roundOff)
      });
    } else {
      this.purchaseForm.patchValue({
        roundOff: -Math.abs(roundOff)
      });
    }
    this.calculateTotalAmount();
  }


  enabled(){
    console.log(this.isEnabled)
    if(this.isEnabled === true){
    this.purchaseForm.patchValue({
      cstatus:true
    })
  } else{
    this.purchaseForm.patchValue({
      cstatus:false
    })
  }
  }
  edit(){
    this.edit1 = true;
  }
  updateDetails(){
    this.rows1.forEach((res:any)=>{
      if(res){
        this.productDetails.push({
          productName:res.productName,
          quantity:res.quantity,
          description:res.description,
          productAmount:this.purchaseForm.get('productAmount')?.value
        })
      }
    })
    const totalAmount1 = this.purchaseForm.get('totalAmount')?.value | 0;
    // const gst = this.purchaseForm.get('gst')?.value | 0;
    // const roundoff = this.purchaseForm.get('roundOff')?.value | 0;
    //console.log(quantity)
   this.productDetails.forEach((val:any)=>{
    if(totalAmount1<0){
     this.quantity = val.quantity
     this.productamt = val.productAmount
     const gst = this.purchaseForm.get('gst')?.value | 0;
     const roundoff = this.purchaseForm.get('roundOff')?.value | 0;
     const sum = Number(this.quantity) * Number(this.productamt) + Number(gst) + Number(roundoff)

     this.purchaseForm.patchValue({
      totalAmount:sum,
      orderAmount:sum
     })
    } else {
      this.quantity = val.quantity
      this.productamt = val.productAmount
      const gst = this.purchaseForm.get('gst')?.value | 0;
      const roundoff = this.purchaseForm.get('roundOff')?.value | 0;
      const sum = Number(this.quantity) * Number(this.productamt) + Number(gst) + Number(roundoff) + Number(totalAmount1)
 
      this.purchaseForm.patchValue({
       totalAmount:sum,
       orderAmount:sum
      })
    }
   })
    console.log(this.productDetails,this.quantity)
  }
}
