<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>Update Purchase</h2>
    <form [formGroup]="purchaseForm">
      <h3>Purchase Details</h3>
      <div class="card">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="companyName">Company Name<span class="requried-field">*</span></label>
          <input
            type="text"
            id="companyName"
            readonly
            class="form-control"
            formControlName="companyName"
            [ngClass]="{ 'is-invalid': submitted && f['companyName'].errors }"
          />
          <div *ngIf="submitted && f['companyName'].errors" class="invalid-feedback">
            <div *ngIf="f['companyName'].errors['required']">Company Name is required</div>
          </div>
        </div>
  
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
            <label for="pocode">Purchase Order Code<span class="requried-field">*</span></label>
            <input
            type="text"
            id="pocode"
            placeholder="Enter pocode"
            class="form-control"
            formControlName="pocode"
            [ngClass]="{ 'is-invalid': submitted && f['pocode'].errors }"
          />
            <div *ngIf="submitted && f['pocode'].errors" class="invalid-feedback">
              <div *ngIf="f['pocode'].errors['required']">Purchase Reference is required</div>
            </div>
        </div>
        <!-- <div class="form-group col-sm-12 col-md-6 col-lg-4">
            <label for="c_ref_code">Company Reference Code<span class="requried-field">*</span></label>
            <input
              type="text"
              id="c_ref_code"
              placeholder="Enter c_ref_code"
              class="form-control"
              formControlName="c_ref_code"
              [ngClass]="{ 'is-invalid': submitted && f['c_ref_code'].errors }"
            />
            <div *ngIf="submitted && f['c_ref_code'].errors" class="invalid-feedback">
              <div *ngIf="f['c_ref_code'].errors['required']">Company Reference is required</div>
            </div>
        </div> -->
       
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
            <label for="supplierName">supplier<span class="requried-field">*</span></label>
            <input
              type="text"
              readonly
              placeholder="Enter supplierId"
              class="form-control"
              formControlName="supplierName"
              [ngClass]="{ 'is-invalid': submitted && f['supplierName'].errors }"
            />
            <div *ngIf="submitted && f['supplierName'].errors" class="invalid-feedback">
              <div *ngIf="f['supplierName'].errors['required']">Supplier Id is required</div>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
            <label for="pe_date">Purchase Date<span class="requried-field">*</span></label>
            <input
               readonly
              placeholder="Enter purchaseDate"
              class="form-control"
              formControlName="pe_date"
              [ngClass]="{ 'is-invalid': submitted && f['pe_date'].errors }"
            />
            <div *ngIf="submitted && f['pe_date'].errors" class="invalid-feedback">
              <div *ngIf="f['pe_date'].errors['required']">PurchaseDate is required</div>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="projectName">Project Name<span class="requried-field">*</span></label>
          <input
            type="text"
           readonly
            placeholder="Enter Project Name"
            class="form-control"
            formControlName="projectName"
            [ngClass]="{ 'is-invalid': submitted && f['projectName'].errors }"
          />
          <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
            <div *ngIf="f['projectName'].errors['required']">Project Name is required</div>
          </div>
        </div>
        
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="siteNo">Site No<span class="requried-field">*</span></label>
          <input
            type="text"
           readonly
            placeholder="Enter Site No"
            class="form-control"
            formControlName="siteNo"
            [ngClass]="{ 'is-invalid': submitted && f['siteNo'].errors }"
          />
          <div *ngIf="submitted && f['siteNo'].errors" class="invalid-feedback">
            <div *ngIf="f['siteNo'].errors['required']">Site No is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label>GST</label>
          <mat-checkbox [(ngModel)]="isEnabled" [ngModelOptions]="{standalone: true}"></mat-checkbox>
        </div>
        </div>
        </div>
       
        <h3>Product Details</h3>
        <div class="card">
        <div class="form-row">
         
            <div class="scroll1">    
            <table class="custom-table">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Description</th> 
                  <th>product Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of rows; let i = index"
                 >
                
                <td>
                  <ng-autocomplete
                  [data]="productName"
                  [searchKeyword]="keyword"
                  placeholder="Enter the Category Name"
                  (selected)='selectEvent($event)'
                  (inputChanged)='onChangeSearch($event)'
                  (inputFocused)='onFocused($event)'
                  historyIdentifier="productName"
                
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate">
                </ng-autocomplete>
              
                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.productName"></a>
                </ng-template>
              
                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
                </td>
                
                 <td>
                  <input
                  type="number"
                  id="quantity"
                  placeholder="Enter quantity"
                  class="form-control"
                  formControlName="quantity"
                  [ngClass]="{ 'is-invalid': submitted && f['quantity'].errors }"
                />
                 </td>
               
                <td>
                  <input
                  type="text"
                  id="description"
                  placeholder="Enter description"
                  class="form-control"
                  formControlName="description"
                  [ngClass]="{ 'is-invalid': submitted && f['description'].errors }"
                />
                </td>
                <td>
                  <input
                  type="number"
                  id="productAmount"
                  placeholder="Enter ProductAmount"
                  class="form-control"
                  (ngModelChange)="productAmount()"
                  formControlName="productAmount"
                  [ngClass]="{ 'is-invalid': submitted && f['productAmount'].errors }"
                />
                </td>
                <td>
                  <mat-icon (click)="add()">add</mat-icon>
                  <mat-icon (click)="getAllData()">save</mat-icon>
                </td>
              </tr>
              <tr *ngFor="let row1 of rows1; let i = index" >
               <td *ngIf="this.tablerow == true">{{row1.productName}}</td>
               <td *ngIf="this.tablerow == true && this.edit1 != true">{{row1.quantity}}</td>
               <td *ngIf="this.edit1 === true && this.tablerow == true">
                  <input
                  type="number"
                  id="quantity"
                  placeholder="Enter quantity"
                  class="form-control"
                  formControlName="quantity"
                  [(ngModel)]="row1.quantity"
                  [ngClass]="{ 'is-invalid': submitted && f['quantity'].errors }"
                />
              </td>
               <td *ngIf="this.tablerow == true">{{row1.description}}</td>
               <td *ngIf="this.tablerow == true">
                <input
                type="number"
                id="productAmount"
                placeholder="Enter ProductAmount"
                class="form-control"
                (ngModelChange)="calculateTotalAmount()"
                formControlName="productAmount"
                [ngClass]="{ 'is-invalid': submitted && f['productAmount'].errors }"
              />
              </td>
              <td *ngIf="this.tablerow == true"> 
                <mat-icon (click)="getAllDatas()">save</mat-icon>
                <mat-icon (click)="edit()">edit</mat-icon>
              </td>
              <!-- <td *ngIf="this.tablerow == true && this.edit1 == true"> <mat-icon (click)="updateDetails()">save</mat-icon>
                <mat-icon (click)="edit()">edit</mat-icon>
              </td> -->
              </tr>
             
              </tbody>
            </table>
          </div>
        </div>
        <div class="form-row">
        <!-- <div class="form-group col-sm-12 col-md-6 col-lg-4">

          <label for="totalProducts">Total Products<span class="requried-field">*</span></label>
          <input
            type="number"
            id="totalProducts"
            placeholder="Enter site"
            class="form-control"
            formControlName="totalProducts"
            [ngClass]="{ 'is-invalid': submitted && f['totalProducts'].errors }"
          />
          <div *ngIf="submitted && f['totalProducts'].errors" class="invalid-feedback">
            <div *ngIf="f['totalProducts'].errors['required']">Total Products is required</div>
          </div>
        </div> -->
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="orderAmount">Order Amount<span class="requried-field">*</span></label>
          <input
            type="number"
            id="orderAmount"
            placeholder="Enter orderAmount"
            class="form-control"
            formControlName="orderAmount"
            (ngModelChange)="calculate()"
            
            [ngClass]="{ 'is-invalid': submitted && f['orderAmount'].errors }"
          />
          <div *ngIf="submitted && f['orderAmount'].errors" class="invalid-feedback">
            <div *ngIf="f['orderAmount'].errors['required']">Order Amount is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-4">
          <label for="totalGst">Total Gst<span class="requried-field">*</span></label>
          <input  
            type="text"
            id="totalGst"
            placeholder="Enter totalGst"
            class="form-control"
            formControlName="totalGst"
            (ngModelChange)="calculate()"
            [ngClass]="{ 'is-invalid': submitted && f['totalGst'].errors }"
            [attr.disabled]="!isEnabled ? true : null"
          />
          <div *ngIf="submitted && f['totalGst'].errors" class="invalid-feedback">
            <div *ngIf="f['totalGst'].errors['required']">GST is required</div>
          </div>
        </div>
       
            <!-- <div class="form-group col-sm-12 col-md-6 col-lg-4">
              <label for="totalDiscount">Total Discount <span class="requried-field">*</span></label>
              <input
                type="number"
                id="totalDiscount"
                placeholder="Enter totalDiscount"
                class="form-control"
                formControlName="totalDiscount"
                [ngClass]="{ 'is-invalid': submitted && f['totalDiscount'].errors }"
              />
              <div *ngIf="submitted && f['totalDiscount'].errors" class="invalid-feedback">
                <div *ngIf="f['totalDiscount'].errors['required']">Discount is required</div>
              </div>
            </div> -->
      
            <div class="form-group col-sm-12 col-md-6 col-lg-4">
              <label for="roundOff">Round Off</label>
              <input
                type="text"
                id="roundOff"
                placeholder="Enter roundOff"
                class="form-control"
                formControlName="roundOff"
                (ngModelChange)="calculate()"
                [ngClass]="{ 'is-invalid': submitted && f['roundOff'].errors }"
              />
              <div *ngIf="submitted && f['roundOff'].errors" class="invalid-feedback">
                <div *ngIf="f['roundOff'].errors['required']">Round Off is required</div>
              </div>
            </div>
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="totalAmount">Total Amount<span class="requried-field">*</span></label>
          <input
            type="number"
            id="totalAmount"
            readonly
            placeholder="Enter totalAmount"
            class="form-control"
            formControlName="totalAmount"
            [ngClass]="{ 'is-invalid': submitted && f['totalAmount'].errors }"
          />
          <div *ngIf="submitted && f['totalAmount'].errors" class="invalid-feedback">
            <div *ngIf="f['totalAmount'].errors['required']">Total Amount is required</div>
          </div>
        </div>
        </div>
        </div>
        <h3>Payment</h3>
        <div class="card">
        <div class="form-row">
        <!-- <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="oc_reason">OC Reason<span class="requried-field">*</span></label>
          <input
            type="text"
            id="oc_reason"
            placeholder="Enter OC Reason"
            class="form-control"
            formControlName="oc_reason"
            [ngClass]="{ 'is-invalid': submitted && f['oc_reason'].errors }"
          />
          <div *ngIf="submitted && f['oc_reason'].errors" class="invalid-feedback">
            <div *ngIf="f['oc_reason'].errors['required']">OC Reason is required</div>
          </div>
        </div>
        
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="oc_amount">OC Amount<span class="requried-field">*</span></label>
          <input
            type="number"
            id="oc_amount"
            placeholder="Enter OC Amount"
            class="form-control"
            formControlName="oc_amount"
            [ngClass]="{ 'is-invalid': submitted && f['oc_amount'].errors }"
          />
          <div *ngIf="submitted && f['oc_amount'].errors" class="invalid-feedback">
            <div *ngIf="f['oc_amount'].errors['required']">OC Amount is required</div>
          </div>
        </div>
        
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="amountToPay">Amount to Pay<span class="requried-field">*</span></label>
          <input
            type="number"
            id="amountToPay"
            placeholder="Enter Amount to Pay"
            class="form-control"
            formControlName="amountToPay"
            [ngClass]="{ 'is-invalid': submitted && f['amountToPay'].errors }"
          />
          <div *ngIf="submitted && f['amountToPay'].errors" class="invalid-feedback">
            <div *ngIf="f['amountToPay'].errors['required']">Amount to Pay is required</div>
          </div>
        </div>
        
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="paidAmount">Paid Amount<span class="requried-field">*</span></label>
          <input
            type="number"
            id="paidAmount"
            placeholder="Enter Paid Amount"
            class="form-control"
            formControlName="paidAmount"
            [ngClass]="{ 'is-invalid': submitted && f['paidAmount'].errors }"
          />
          <div *ngIf="submitted && f['paidAmount'].errors" class="invalid-feedback">
            <div *ngIf="f['paidAmount'].errors['required']">Paid Amount is required</div>
          </div>
        </div>
        
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="useWallet">Use Wallet<span class="requried-field">*</span></label>
          <input
            type="text"
            id="useWallet"
            placeholder="Enter Use Wallet"
            class="form-control"
            formControlName="useWallet"
            [ngClass]="{ 'is-invalid': submitted && f['useWallet'].errors }"
          />
          <div *ngIf="submitted && f['useWallet'].errors" class="invalid-feedback">
            <div *ngIf="f['useWallet'].errors['required']">Use Wallet is required</div>
          </div>
        </div>
        
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="empId">Employee ID<span class="requried-field">*</span></label>
          <input
            type="text"
            id="empId"
            placeholder="Enter Employee ID"
            class="form-control"
            formControlName="empId"
            [ngClass]="{ 'is-invalid': submitted && f['empId'].errors }"
          />
          <div *ngIf="submitted && f['empId'].errors" class="invalid-feedback">
            <div *ngIf="f['empId'].errors['required']">Employee ID is required</div>
          </div>
        </div>
         -->
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="purchaseMode">Purchase Mode<span class="requried-field">*</span></label>
          <select
                    id="purchaseMode"
                    name="purchaseMode"
                    class="form-control"
                    formControlName="purchaseMode"
                    [(ngModel)]="ispurchase"
                    (ngModelChange)="purchased($event)"
                    [ngClass]="{ 'is-invalid': submitted && f['maintainStock'].errors }"
                  >
                  <option value="" selected disabled>--Please select--</option>
                    <option
                      *ngFor="let data of purchaseMode"
                      [value]="data"
                      >{{ data }}
                    </option>
                  </select>
          <div *ngIf="submitted && f['purchaseMode'].errors" class="invalid-feedback">
            <div *ngIf="f['purchaseMode'].errors['required']">Purchase Mode is required</div>
          </div>
        </div>
        
       
        
        <!-- <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="billfile">Bill File<span class="requried-field">*</span></label>
          <input
            type="file"
            id="billfile"
            class="form-control"
            formControlName="billfile"
            (change)="handleFileImage($event.target.files)"
            [ngClass]="{ 'is-invalid': submitted && f['billfile'].errors }"
          />
         
        </div> -->
        
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="paymentMode">Payment Mode<span class="requried-field">*</span></label>
          <select
                    id="paymentMode"
                    name="paymentMode"
                    class="form-control"
                    formControlName="paymentMode"
                    [(ngModel)]="ispayment"
                    (ngModelChange)="payment($event)"
                    [attr.disabled]="iscredit ? true : null"
                    [ngClass]="{ 'is-invalid': submitted && f['paymentMode'].errors }"
                  >
                  <option value="" selected disabled>--Please select--</option>
                    <option
                      *ngFor="let data of paymentMode"
                      [value]="data"
                      >{{ data }}
                    </option>
                  </select>
          <div *ngIf="submitted && f['paymentMode'].errors" class="invalid-feedback">
            <div *ngIf="f['paymentMode'].errors['required']">Payment Mode is required</div>
          </div>
        </div>
        
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="chequeNo">Cheque No<span class="requried-field">*</span></label>
          <input
            type="text"
            id="chequeNo"
            placeholder="Enter Cheque No"
            class="form-control"
            formControlName="chequeNo"
            [attr.disabled]="iscash ? true : null"
            [ngClass]="{ 'is-invalid': submitted && f['chequeNo'].errors }"
          />
          <div *ngIf="submitted && f['chequeNo'].errors" class="invalid-feedback">
            <div *ngIf="f['chequeNo'].errors['required']">Cheque No is required</div>
          </div>
        </div>
        
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="accountNo">Account No<span class="requried-field">*</span></label>
          <input
            type="text"
            id="accountNo"
            placeholder="Enter DD No"
            class="form-control"
            formControlName="accountNo"
            [attr.disabled]="iscash? true : null"
            [ngClass]="{ 'is-invalid': submitted && f['accountNo'].errors }"
          />
          <div *ngIf="submitted && f['accountNo'].errors" class="invalid-feedback">
            <div *ngIf="f['accountNo'].errors['required']">Account No is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="withdrawDate">Withdraw Date<span class="requried-field">*</span></label>
          <input
            type="date"
            id="withdrawDate"
            class="form-control"
            formControlName="withdrawDate"
            [attr.disabled]="iscash ? true : null"
            [ngClass]="{ 'is-invalid': submitted && f['withdrawDate'].errors }"
          />
          <div *ngIf="submitted && f['withdrawDate'].errors" class="invalid-feedback">
            <div *ngIf="f['withdrawDate'].errors['required']">Withdraw Date is required</div>
          </div>
        </div>
        
        <!-- <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="transactionNo">Transaction No<span class="requried-field">*</span></label>
          <input
            type="text"
            id="transactionNo"
            placeholder="Enter Transaction No"
            class="form-control"
            formControlName="transactionNo"
            [ngClass]="{ 'is-invalid': submitted && f['transactionNo'].errors }"
          />
          <div *ngIf="submitted && f['transactionNo'].errors" class="invalid-feedback">
            <div *ngIf="f['transactionNo'].errors['required']">Transaction No is required</div>
          </div>
        </div> -->
        
        
        
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="receiptNo">Receipt No<span class="requried-field">*</span></label>
          <input
            type="text"
            id="receiptNo"
            placeholder="Enter Receipt No"
            class="form-control"
            [attr.disabled]="ischeque ? true : null"
            formControlName="receiptNo"
            [ngClass]="{ 'is-invalid': submitted && f['receiptNo'].errors }"
          />
          <div *ngIf="submitted && f['receiptNo'].errors" class="invalid-feedback">
            <div *ngIf="f['receiptNo'].errors['required']">Receipt No is required</div>
          </div>
        </div>
        
        <!-- <div class="form-group col-sm-12 col-md-6 col-lg-4">
          <label for="status">Status<span class="requried-field">*</span></label>
          <input
            type="text"
            id="status"
            placeholder="Enter Status"
            class="form-control"
            formControlName="status"
            [ngClass]="{ 'is-invalid': submitted && f['status'].errors }"
          />
          <div *ngIf="submitted && f['status'].errors" class="invalid-feedback">
            <div *ngIf="f['status'].errors['required']">Status is required</div>
          </div>
        </div> -->
      </div>
    </div>

      
    <div class="formbutton">
      <button type="submit" class="btn btn-primary" (click)="onSubmit(purchaseForm.value)">Submit</button>
      <button type="submit" class="btn btn-danger btn2" (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  