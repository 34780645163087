<div class="container-fluid">
    <div class="filters">
        <label for="supplier">Supplier Name</label>
        <input type="text" class="dropdown"/>
        <label for="category">Category</label>
        
        <select id="category" class="dropdown">
            <!-- Add options here -->
        </select>
        <label for="location">Location</label>
       
        <select id="location" class="dropdown">
            <!-- Add options here -->
        </select>
        <label for="price">Price</label>
        
        <select id="price" class="dropdown">
            <!-- Add options here -->
        </select>
        <label for="unit">Unit</label>
       
        <select id="unit" class="dropdown">
            <!-- Add options here -->
        </select>
        <button id="addButton" class="btn btn-primary add-button" (click)="add()">+</button>
    </div>
    <div class="scroll">
        <table
    class="custom-table">
    <thead>
      <tr>
        <th>Company</th>
        <th>Supplier</th>
        <th>Project</th>
        <th>Site</th>
        <th>Total Amount</th>
        <th>Payment Method</th>
        <th>Date</th>
        <th>Action</th>
        
        </tr>
        </thead>
        <tbody >
            <tr
            *ngFor="
            let element of purchaseList
            | paginate: { itemsPerPage: 25, currentPage: page }
          "
            >
                
              <td>{{element.companyName}}</td>
              <td>{{element.supplierName}}</td>
              <td>{{element.projectName}}</td>
              <td>{{element.siteNo}}</td>
              <td>{{element.totalAmount}}</td>
              <td>{{element.paymentMode}}</td>
              <td>{{element.pe_date}}</td>
              <td>
                <mat-icon (click)="onEdit(element.id)">edit</mat-icon>
                <mat-icon (click)="onDelete(element.id)" >delete</mat-icon>
             </td>
             
            </tr>
            </tbody>
            </table>
     </div>

   
</div>